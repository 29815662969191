// src/routing/route-config.tsx
import {
  createEntityRoutes,
  ForgotPasswordPage,
  LoginPage,
  OnboardingPage,
  ProfilePage,
  ProjectViewPage,
  RegisterPage,
  ResetPasswordPage,
  VerifyEmailPage,
  WalletPage,
} from "@fraktion/core-frontend";
import { DashboardPage } from "@/pages/dashboard/dashboard.page";

export const routes = [
  {
    path: "/onboarding",
    component: OnboardingPage,
    label: "Onboarding",
    translationKey: "nav.onboarding",
    icon: "onboarding",
    showInNav: false,
  },
  {
    path: "/dashboard",
    component: DashboardPage,
    label: "Dashboard",
    translationKey: "nav.dashboard",
    icon: "home",
    showInNav: true,
  },
  {
    path: "/projects/:id/view",
    component: ProjectViewPage,
    label: "Project View",
    translationKey: "nav.projectView",
    icon: "projects",
  },
  ...createEntityRoutes("projects", {
    translationKey: "nav.projects",
    icon: "projects",
    showInNav: true,
    defaultViewMode: "card",
    showViewModeSwitch: false,
    listPath: "/projects",
    listTitleKey: "crud.list.marketplace",
    generateRoutes: {
      list: true,
    },
  }),
  ...createEntityRoutes("orders-user", {
    translationKey: "nav.investments",
    icon: "list",
    showInNav: true,
    defaultViewMode: "table",
    showViewModeSwitch: false,
    listPath: "/investissements",
    generateRoutes: {
      list: true,
    },
  }),
  {
    path: "/wallet",
    component: WalletPage,
    label: "Wallet",
    translationKey: "nav.wallet",
    icon: "wallet",
    showInNav: true,
  },
  {
    path: "/profile",
    component: ProfilePage,
    label: "Profile",
    translationKey: "nav.profile",
    icon: "user",
    showInNav: true,
  },
  {
    path: "/auth/login",
    component: LoginPage,
    label: "Login",
    translationKey: "nav.login",
    icon: "login",
    showInNav: false,
    public: true,
  },
  {
    path: "/auth/register",
    component: RegisterPage,
    label: "Register",
    translationKey: "nav.register",
    icon: "register",
    showInNav: false,
    public: true,
  },
  {
    path: "/auth/forgot-password",
    component: ForgotPasswordPage,
    label: "Forgot Password",
    translationKey: "nav.forgotPassword",
    icon: "password",
    showInNav: false,
    public: true,
  },
  {
    path: "/auth/reset-password",
    component: ResetPasswordPage,
    label: "Reset Password",
    translationKey: "nav.resetPassword",
    icon: "password",
    showInNav: false,
    public: true,
  },
  {
    path: "/auth/verify-email",
    component: VerifyEmailPage,
    label: "Verify Email",
    translationKey: "nav.verifyEmail",
    icon: "email",
    showInNav: false,
    public: true,
  },
];
