import React, { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  PageHeader,
  KPICard,
  LineChart,
  EmptyState,
  InvestmentDistribution,
  SelectOption,
  StatCardProps,
  formatCurrency,
  // UserStats200ResponseValue,
  useApi,
} from "@fraktion/core-frontend";

interface UserStats200ResponseValue {
  name?: string;
  invested?: number;
  revenue?: number;
  yield?: number;
}

export const DashboardPage: React.FC = () => {
  const { t } = useTranslation(["dashboard", "common"]);
  const [startDate, setStartDate] = useState(new Date("2023-09-27"));
  const [endDate, setEndDate] = useState(new Date("2024-09-27"));
  const [selectedCampaign, setSelectedCampaign] = useState("all");
  const navigate = useNavigate();

  // Add after the existing state declarations
  const apiClient = useApi();
  const [isStatsLoading, setIsStatsLoading] = useState(true);
  const [statsError, setStatsError] = useState<string | null>(null);

  // Update the state type to match the API response
  const [userStats, setUserStats] = useState<Record<
    string,
    UserStats200ResponseValue
  > | null>(null);

  // Update API call
  useEffect(() => {
    const fetchUserStats = async () => {
      setIsStatsLoading(true);
      setStatsError(null);
      try {
        const stats = await apiClient.usersApi.userInfoStats();
        setUserStats(stats);
      } catch (error) {
        console.error("Error fetching user stats:", error);
        setStatsError("Failed to fetch user statistics");
      } finally {
        setIsStatsLoading(false);
      }
    };

    fetchUserStats();
  }, [apiClient]);

  // Update the investment distribution calculation
  const investmentDistributionData = useMemo(() => {
    if (!userStats)
      return {
        data: {
          labels: [],
          datasets: [
            {
              data: [],
              backgroundColor: ["#10100F", "#C9C2C2", "#FFD700"],
              hoverBackgroundColor: ["#10100F", "#C9C2C2", "#FFD700"],
            },
          ],
        },
        percentageChanges: [],
      };

    // Transform the object data into arrays
    const projects = Object.values(userStats);
    const total = projects.reduce((sum, p) => sum + (p.invested || 0), 0);

    // Group by project name
    const investmentsByType = projects.reduce(
      (acc: Record<string, number>, project) => {
        const type = project.name || "Other";
        acc[type] = (acc[type] || 0) + (project.invested || 0);
        return acc;
      },
      {}
    );

    const labels = Object.keys(investmentsByType);
    const values = Object.values(investmentsByType);
    const percentages = values.map((value) =>
      total ? Number(((value / total) * 100).toFixed(1)) : 0
    );

    return {
      data: {
        labels,
        datasets: [
          {
            data: percentages,
            backgroundColor: ["#10100F", "#C9C2C2", "#FFD700"],
            hoverBackgroundColor: ["#10100F", "#C9C2C2", "#FFD700"],
          },
        ],
      },
      percentageChanges: values.map(() => 0), // We don't have historical data yet
    };
  }, [userStats]);

  const amountCollectedChartData = {
    labels: [
      "Sep",
      "Oct",
      "Nov",
      "Dec",
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
    ],
    datasets: [
      {
        label: t("dashboard.campaignOne"),
        data: [100, 220, 180, 200, 280, 350, 370, 320, 270, 160, 100, 140, 300],
        borderColor: "#6E6E6E",
        backgroundColor: "#1B59F8",
        gradientStops: {
          start: "rgba(255, 215, 0, 1)",
          middle: "rgba(255, 215, 0, 1)",
          end: "rgba(255, 215, 0, 0)",
        },
        fill: true,
        order: 2,
      },
      {
        label: t("dashboard.campaignTwo"),
        data: [50, 100, 80, 180, 220, 280, 300, 240, 220, 180, 160, 140, 200],
        borderColor: "#FF7D00",
        backgroundColor: "#11A2B6",
        gradientStops: {
          start: "rgba(255, 125, 0, 1)",
          middle: "rgba(255, 125, 0, 0.1)",
          end: "rgba(255, 125, 0, 0)",
        },
        fill: true,
        order: 1,
      },
    ],
  };

  const chartOptions = {
    responsive: true,
    plugins: {
      legend: {
        display: true,
        position: "bottom" as const,
        align: "start" as const,
        labels: {
          usePointStyle: true,
          pointStyle: "rectRounded",
          padding: 20,
          font: {
            size: 12,
          },
          boxWidth: 16,
          boxHeight: 16,
        },
      },
      title: {
        display: false,
      },
    },
    scales: {
      y: {
        beginAtZero: true,
        grid: {
          color: "rgba(0, 0, 0, 0.1)",
        },
      },
      x: {
        grid: {
          display: false,
        },
      },
    },
    elements: {
      line: {
        tension: 0.4,
      },
      point: {
        radius: 0,
      },
    },
  };

  const campaignOptions: SelectOption[] = [
    { value: "all", label: t("common:investments.allInvestments") },
    { value: "investmentA", label: t("common:investments.investmentA") },
    { value: "investmentB", label: t("common:investments.investmentB") },
  ];

  const kpiData: StatCardProps[] = [
    {
      title: t("dashboard.investedAmount"),
      value: formatCurrency(
        Object.values(userStats || {}).reduce(
          (sum, p) => sum + (p.invested || 0),
          0
        ),
        {
          currency: "EUR",
          notation: "compact",
          minimumFractionDigits: 0,
          maximumFractionDigits: 0,
        }
      ),
      icon: "user",
      linkText: t("common:common.seeMore"),
      description: t("dashboard.investedAmountDescription"),
      link: "/",
    },
    {
      title: t("dashboard.receivedRevenues"),
      value: formatCurrency(0, {
        currency: "EUR",
        notation: "compact",
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      }),
      icon: "check",
      linkText: t("common:common.seeMore"),
      description: t("dashboard.receivedRevenuesDescription"),
      link: "/",
    },
    {
      title: t("dashboard.averageYield"),
      value: "0%",
      icon: "wallet",
      linkText: t("common:common.seeMore"),
      description: t("dashboard.averageYieldDescription"),
      link: "/",
    },
  ];

  return (
    <div className="space-y-8">
      <PageHeader title={t("dashboard.dashboardTitle")} />
      {statsError ? (
        <div className="text-red-500">{statsError}</div>
      ) : (
        <>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
            {kpiData.map((kpi, index) => (
              <KPICard key={index} {...kpi} isLoading={isStatsLoading} />
            ))}
          </div>
          <div className="grid grid-cols-3 gap-8 h-full">
            <div className="col-span-2 flex flex-col">
              <div className="col-span-2 flex flex-col">
                <LineChart
                  title={t("dashboard.receivedRevenues")}
                  mainValue={150042}
                  changeValue={3.5}
                  isPositiveChange={true}
                  startDate={startDate}
                  endDate={endDate}
                  onStartDateChange={setStartDate}
                  onEndDateChange={setEndDate}
                  selectedFilter={[selectedCampaign]}
                  filterOptions={campaignOptions}
                  onFilterChange={(value) => setSelectedCampaign(value[0])}
                  chartData={amountCollectedChartData}
                  chartOptions={chartOptions}
                  renderEmptyState={() => (
                    <EmptyState
                      icon="coins"
                      title={t("dashboard.noRevenuesTitle")}
                      description={t("dashboard.noRevenuesDescription")}
                      primaryAction={{
                        label: t("dashboard.projectInvestment"),
                        onClick: () => {
                          navigate("/marketplace");
                        },
                        icon: "plus",
                      }}
                    />
                  )}
                />
              </div>
            </div>
            <div className="col-span-1 flex flex-col">
              <InvestmentDistribution
                data={investmentDistributionData.data}
                percentageChanges={investmentDistributionData.percentageChanges}
              />
            </div>
          </div>
          {/* <div className="grid grid-cols-1 lg:grid-cols-2 gap-8">
        <RecentOperations />
        <MyInvestments />
      </div> */}
        </>
      )}
    </div>
  );
};

export default DashboardPage;
